/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import "./layout.css"
import Nav from "./nav"
import Footer from "./footer"

const Layout = ({ children }) => {

  return (
    <>
    <main className="page-main">
      <Nav />
      <div className="children">
      {children}
      </div>
      <Footer /> 
    </main>    
    
    </> 
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
