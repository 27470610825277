import React from 'react'
import { FaInstagram, FaFacebookF, FaTwitter } from 'react-icons/fa'
import { Link } from 'gatsby'
// import { useIntl, Link } from "gatsby-plugin-intl"

const MobNav = ( ) => {
  // const intl = useIntl()
  return(
  <div className="nav hide-on-large-only">
    <input type="checkbox" className="nav__checkbox" id="nav-toggle" />
    <label htmlFor="nav-toggle" className="nav__button">
        <span className="nav__icon">&nbsp;</span>
    </label>

    <div className="nav__bg">&nbsp;</div>

    <div className="nav__nav" id="nav">
        <ul className="nav__list">
            <li className="nav__item">
                <Link to="/doctors" className="nav__link">Nos m&eacute;decins</Link>
            </li>
            <li className="nav__item">
                <Link to="/medpros" className="nav__link">Autres professionels de sant&eacute;</Link>
            </li>                     
            <li className="nav__item">
                <Link to="/doctor" className="nav__link">Vous &ecirc;tes m&eacute;decin?</Link>
            </li> 
            <li className="nav__item">
                <Link to="/signin" className="nav__link">Se Connecter</Link>
            </li> 
            <li className="nav__item">
                <Link to="/contact" className="nav__link">Contact</Link>
            </li>                                
            {/* <li className="nav__item">
                <Link to="/" className="nav__link">Contact</Link>
            </li>
            <li className="nav__item">
                <Link to="/app" className="nav__link">Allô doc</Link>
            </li>  */}
        </ul>
        <ul className="nav__socials left">
          <li><a className="color0" aria-label="Facebook" href="https://web.facebook.com/allodocapp" target="_blank" rel="noopener noreferrer"><FaFacebookF size="2em" /></a></li>
          <li><a className="color0" aria-label="Instagram" href="https://instagram.com/allodocapp" target="_blank" rel="noopener noreferrer"><FaInstagram size="2em" /></a></li>
          <li><a className="color0" aria-label="Twitter" href="https://twitter.com/allodocapp" target="_blank" rel="noopener noreferrer"><FaTwitter size="2em" /></a></li>
        </ul>
    </div>
  </div>
)}

export default MobNav