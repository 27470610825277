import React, {useState, useRef, useEffect, useContext} from "react";
import { Link } from "gatsby"
import { gsap } from 'gsap'
import { SlowMo } from 'gsap/EasePack'
import MobNav from './mobilenav'
import { StaticImage } from "gatsby-plugin-image";
import { AuthContext } from '../firebase'
import { isMobile } from 'react-device-detect';
gsap.registerPlugin(SlowMo)



const Nav = (props) => {

  const userInit = {myUser: ""}
  const { authUser }  = useContext(AuthContext) || userInit
  const { user } = authUser || userInit

  // console.log(`authUser`, authUser)
  // console.log('user :>> ', user);

  let navEl = useRef(null)
  // let titleEl = useRef(null)
  const path = typeof window !== 'undefined' ? window.location.pathname : '';


  const [isIndex, setIsIndex] = useState(path === "/")


  useEffect(() => {
    // gsap.to(titleEl, {opacity: 1, duration: 1.2, x: 20, ease: "slow(0.7, 0.4, false)", delay: .3})
    gsap.to(navEl.children, {opacity: 1, duration: .8, y: 20, ease: "back", delay: .2, stagger: 0.1})
  })


  return(
  // <div className="navbar-fixed">
    <>
    <nav className="transparent"> 

      <div className="nav-wrapper">
        <div className="container1">
          <Link to="/" className="brand-logo navbar-brand">
            {isIndex 
              ? 
                isMobile
                ?
                <StaticImage src="../images/logoblue.png" alt="Allo Doc logo white" height={58} layout="fixed" placeholder="none" />
                :
                <StaticImage src="../images/logowhite.png" alt="Allo Doc logo white" height={58} layout="fixed" placeholder="none" />
              
              : 
              <StaticImage src="../images/logoblue.png" alt="Allo Doc logo white" height={58} layout="fixed" placeholder="none" />
            }
            {/* <h3 className="brand-name color-0" ref={el => titleEl = el}>Allo Doc</h3> */}
          </Link>
          <ul  className="right hide-on-med-and-down color-1-text" ref={el => navEl = el}>            
            <li  className="hidden">
              <Link to="/"></Link>
            </li>
            <li className="navEl"><Link className="center-align color-4" to="/doctors">Nos m&eacute;decins</Link></li>
            <li className="navEl"><Link className="center-align color-4" to="/medpros">Autres professionels de sant&eacute;</Link></li>
            {/* {
              !user &&
                <li className="navEl" >
                  <Link className="center-align color-2" to="/signup">S'inscrire</Link>
                </li>
            } */}

           
           {
              user && 
                <li className="navEl center-align" >
                  <Link className="navbtn color-3 color-0-bg" to="/app/dashboard">App</Link>
                </li> 
            }
            {
              !user &&
                <li className="navEl" >
                  <Link className="center-align color-4" to="/doctor">Vous &ecirc;tes m&eacute;decin?</Link>
                </li>
            }
            {
              !user &&
                <li className="navEl center-align" >
                  <Link className="color-0" to="/signin">Se Connecter</Link>
                </li>
            }
            {/* <li ref={el => navEl[3] = el}><Link className="center-align" to="/media">Media</Link></li> */}
            {/* <li className="navEl" ref={el => navEl[4] = el}>
              <Link className="center-align" to="/contact">Contact</Link>
            </li>      */}
            <li className="navEl">
              {/* <Link className="center-align langBg" to="/"><div className="lang">FR</div></Link>              */}
            </li>       
          </ul>
          
        </div>
      </div>
    </nav>
    <MobNav />
    </>
  // </div>
)};
export default Nav;