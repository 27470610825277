import React from "react";
import {Row, Col, Icon} from "react-materialize"
import { Link } from "gatsby"
import { FaInstagram, FaFacebookF, FaTwitter } from 'react-icons/fa';
import { StaticImage } from "gatsby-plugin-image";



const Footer = () => (

  <footer >
    <div className="page-footer color-0-bg">
    <div className="container1">
      <Row>
        <Col l={4} m={12} s={12} className="hide-on-med-and-down">
        {/* <Link to="/" ><Image className="footer-logo" /></Link> */}
          <Link to="/" >
            {/* <h3 className="footer-title">Allo Doc</h3> */}
            <StaticImage src="../images/allowhite.png" alt="Allo Doc footer logo" height={150} layout="fixed" placeholder="none" className="footer-image" />
            {/* <Image mystyle="footer-image" /> */}
          </Link>
          <p className="slogan">La sant&eacute; en un click!</p>
        </Col>
        <Col l={4} m={12} s={12} className="center hide-on-large-only">
        {/* <Link to="/" ><Image className="footer-logo" /></Link> */}
        <Link to="/" >
          <h3 className="footer-title">Allo Doc</h3>
          <StaticImage src="../images/allowhite.png" alt="Allo Doc footer logo" height={150} layout="fixed" placeholder="none" className="footer-image" />
        </Link>
        <p>La sant&eacute; en un click</p>
        </Col>
        <Col l={3} m={4} s={4}>
          <h3 className="footer-list-head">Pages</h3>
          <ul className="footer-list">
            {/* <li><Link className="" to="/">Accueil</Link></li> */}
            <li><Link className="" to="/doctors">Medecins</Link></li>
            <li><Link className="" to="/signup">S'inscrire</Link></li>
            <li><Link className="" to="/signin">Se connecter</Link></li>
            {/* <li><Link className="" to="/media">Media</Link></li> */}
            <li><Link className="" to="/contact">Contact</Link></li>
            {/* <li><Link className="" to="/app">Allô doc</Link></li> */}
            <li><Link className="" to="/terms">Termes et Conditions</Link></li>
          </ul>
        </Col>
        <Col l={5} m={8} s={8}>
          <h3 className="footer-list-head">Contact</h3>
            <ul className="socials footer-list">
              <li><a className="" aria-label="Facebook" href="https://web.facebook.com/allodocapp"><FaFacebookF /></a></li>
              <li><a className="" aria-label="Twitter" href="https://twitter.com/allodocapp"><FaTwitter /></a></li>
              <li><a className="" aria-label="Instagram" href="https://instagram.com/allodocapp"><FaInstagram /></a></li>
              {/* <li><Link className="grey-text text-lighten-3" to="/contact">Contact</Link></li> */}
            </ul>
            <ul className="footer-list contact">
              <li><Icon> email </Icon> : info@allodoc.app</li>
              <li><Icon> phone </Icon> : +50947007622, +50949007632</li>
              {/* <li><Icon> place </Icon> : 15, angle rues Métellus et Borno (entrée de Montagne Noire), PV, Haiti</li> */}
            </ul>
        </Col>
      </Row>
    </div>
    <div className="footer-copyright">
      {/* <div className="container1"> */}
      © 2021, Powered by
      <a className="grey-text text-lighten-4 right" href="https://bizhightech.com/">BizHighTech</a>
      {/* </div> */}
    </div>
    </div>
  </footer>

)

export default Footer